import posterImage from "../assests/images/HomePageVideoPoster.webp";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ObituaryState {
  obituary: {
    videoUrl: string;
    thumbnailUrl: string;
    previewStatus: string;
  };
}

const initialState: ObituaryState = {
  obituary: {
    videoUrl: "/demo_video.mp4",
    thumbnailUrl: posterImage,
    previewStatus: ""
  },
};

export const obituarySlice = createSlice({
  name: "obituaryData",
  initialState,
  reducers: {
    setObituary: (
      state,
      action: PayloadAction<{ videoUrl: string; thumbnailUrl: string , previewStatus: string }>
    ) => {
      state.obituary = action.payload;
    },
    resetVideoState: (state) => {
      state.obituary = initialState.obituary;
    },
  },
});

export const { setObituary,resetVideoState } = obituarySlice.actions;

export default obituarySlice.reducer;
