import { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Loader } from "./widgets/loader";

const Layout = lazy(() => import("./pages/layout"));

const App = () => {
  return (
    <HelmetProvider>
    <BrowserRouter>
      <Suspense fallback={<Loader loader={true} />}>
        <Layout />
      </Suspense>
    </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
